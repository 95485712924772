<template>
  <!-- 发起售后 -->
  <Layout>
    <div class="main-after-sales">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
      </div>
      <div class="layout">
        <!-- 商品 -->
        <div class="md-as-1" v-ani.fade-up>
          <div class="c-title-2 s22">售后商品</div>
          <div class="c-card bd">
            <!-- 商品 -->
            <div class="goods flex-middle" v-if="goods">
              <img src="@/assets/images/prod-4.jpg" alt="" class="cover">
              <div class="text s16 color-4 flex-1">
                <div class="text-item">
                  <span class="t1 color-9">商品标题：</span>
                  <span class="t2">泳池水下灯</span>
                </div>
                <div class="text-item">
                  <span class="t1 color-9">规格：</span>
                  <span class="t2">50*50</span>
                </div>
                <div class="text-item">
                  <span class="t1 color-9">数量：</span>
                  <span class="t2">50</span>
                </div>
              </div>
              <img src="@/assets/images/icon-delete-2.png" alt="" class="btn-delete" @click="goods=''">
            </div>
            <!-- 选择商品 -->
            <div class="choose pointer flex-center" v-else @click="visible = true">
              <div class="s16 color-blue font-medium">
                <img src="@/assets/images/icon-add-3.png" alt="">
                <p>请选择产品 </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 表单 -->
        <div class="md-as-2 c-card" v-ani.fade-up>
          <form class="form s16 color-4">
            <!-- 收货人姓名 -->
            <div class="form-item flex-middle">
              <label class="form-item_label s18 color-2 font-medium required">
                <div><p class="p_label">反馈原因</p></div>
              </label>
              <div class="form-item_content">
                <BaseRadio :options='options' v-model="reason"  />
              </div>
            </div>
            <!-- 其他原因说明 -->
            <div class="form-item">
              <label class="form-item_label s18 color-2 font-medium h50">
                <div><p class="p_label">其他原因说明</p></div>
              </label>
              <div class="form-item_content">
                <el-input
                  type="textarea"
                  placeholder="请输入其他原因说明"
                  v-model="content"
                  maxlength="100"
                >
                </el-input>
              </div>
            </div>
            <!-- 处理方案 -->
            <div class="form-item flex-middle">
              <label class="form-item_label s18 color-2 font-medium required">
                <div><p class="p_label">处理方案</p></div>
              </label>
              <div class="form-item_content">
                <BaseRadio :options='options2' v-model="solution"  />
              </div>
            </div>
            <!-- 商品信息 -->
            <div class="form-item">
              <label class="form-item_label s18 color-2 font-medium required">
                <div><p class="p_label">商品信息</p></div>
              </label>
              <div class="form-item_content">
                <!-- 补发 -->
                <div class="sub-form" v-show="solution == 1">
                  <div class="sub-form-item">本次送货数量：3</div>
                  <div class="sub-form-item flex-middle">
                    <div class="f1">实际收货数量<span class="color-red">*</span>：</div>
                    <div class="f2">
                      <input type="text" class="ipt" placeholder="请输入实际收货数量">
                    </div>
                  </div>
                  <div class="sub-form-item">补发数量：3</div>
                  <div class="sub-form-item flex-middle">
                    <div class="f1">收货地址<span class="color-red">*</span>：</div>
                    <div class="f2">
                      <BaseSelect ipt-class='ipt' placeholder="请选择收货地址" :options="addressList" v-model="address" />
                    </div>
                  </div>
                </div>
                <!-- 换货 -->
                <div class="sub-form" v-show="solution == 2">
                  <div class="sub-form-item">换货数量：3</div>
                  <div class="sub-form-item flex-middle">
                    <div class="f1">退货地址<span class="color-red">*</span>：</div>
                    <div class="f2">
                      <BaseSelect ipt-class='ipt' placeholder="请选择退货地址" :options="addressList" v-model="address" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 备注信息 -->
            <div class="form-item">
              <label class="form-item_label s18 color-2 font-medium h50">
                <div><p class="p_label">备注信息</p></div>
              </label>
              <div class="form-item_content">
                <el-input
                  type="textarea"
                  placeholder="请描述您的问题"
                  v-model="content"
                  maxlength="100"
                  show-word-limit
                >
                </el-input>
              </div>
            </div>
            <!-- 图片 -->
            <div class="form-item">
              <label class="form-item_label s18 color-2 font-medium h50">
                <div><p class="p_label">图片</p></div>
              </label>
              <div class="form-item_content">
                <BaseFile
                  placeholder="请上传相关凭证图片"
                  :max='3'
                  v-model="imgs"
                  :type="2"
                />
              </div>
            </div>
            <!-- 提交 -->
            <div class="flex-center">
              <BaseButton
                size='large'
                class="btn"
                tag='button'
                type='submit'
              >
                提交反馈
              </BaseButton>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <BaseDialog
      :visible.sync='visible'
      width="10.63rem"
      title='选择商品'
      class="dialog-goods-list"
      :cancel='false'
      :confirm='false'
    >
      <div class="table">
        <div class="thead flex-middle s16 color-4">
          <div class="td">商品</div>
          <div class="td">剩余收货数量</div>
          <div class="td">发货时间</div>
          <div class="td">货物状态</div>
        </div>
        <div class="tbody">
          <div class="tr flex" v-for="(item, index) in 4" :key="item">
            <div class="td">
              <div class="goods flex-middle">
                <img src="@/assets/images/prod-4.jpg" alt="" class="goods-cover">
                <div class="text">
                  <div class="text-item">
                    <span class="color-9">商品标题：</span>
                    <span>泳池水下灯</span>
                  </div>
                  <div class="text-item">
                    <span class="color-9">规格：</span>
                    <span>50*50</span>
                  </div>
                  <div class="tags flex-wrap s12">
                    <div class="tag color-blue">标签</div>
                    <div class="tag color-blue">标签</div>
                    <div class="tag color-blue">标签</div>
                  </div>
                </div>
                <div>x50</div>
              </div>
            </div>
            <div class="td">50件/吨/个/斤</div>
            <div class="td">{{'2023-01-16  01:04:13' | dateFormat}}</div>
            <div class="td">
              <div class="status flex-center color-blue" v-if="index == 3">
                <img src="@/assets/images/icon-check5.png" alt="">
                <span>已反馈成功</span>
              </div>
              <span v-else class="pointer" @click="handleChooseGoods">选择售后</span>
            </div>
          </div>
        </div>
      </div>
    </BaseDialog>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        { name: '首页', link: '/'},
        { name: '我的订单', link: '/order'},
        { name: '全部订单', link: '/order'},
        { name: '发起售后', link: '' }
      ],
      goods: null,
      visible: false,
      reason: '',
      options: [
        { value: 1, name: '货物数量不对' },
        { value: 2, name: '材料不合格' },
        { value: 3, name: '货不对版' },
        { value: 4, name: '其它原因' },
      ],
      solution: 1,
      options2: [
        { value: 1, name: '补发商品' },
        { value: 2, name: '换货' },
      ],
      address: '',
      addressList: [
        { value: 1, name: '广东省xxxxxxxxxxxxxxxxxxxxxxxxxx' }
      ],
      content: '',
      imgs: []
    };
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleChooseGoods() {
      this.visible = false
      this.goods = true
    }
  }
};
</script>

<style lang="scss" scoped>
.main-after-sales{
  padding-bottom: .4rem;
}
.layout{
  width: 12.8rem;
  max-width: 98%;
  margin-left: auto; margin-right: auto;
}
.md-as-1{
  .bd{
    margin-top: .16rem;
  }
  .choose{
    height: 1.6rem;
    img{
      width: .18rem;
      display: block;
      margin: .12rem auto 0.11rem;
    }
  }
  .goods{
    padding: .3rem;
    position: relative;
    .cover{
      width: 1rem; height: 1rem;
      object-fit: cover;
      border-radius: .04rem;
      margin-right: .19rem;
    }
    .text{
      line-height: calc(30 / 16 * 1em);
      margin-top: .04rem;
      &-item{
        margin-bottom: .01rem;
      }
    }
    .btn-delete{
      position: absolute; right: .2rem; top: .2rem;
      width: .18rem;
      cursor: pointer;
    }
  }
}
.table{
  margin: .3rem;
  .thead{
    height: 0.5rem;
	  background-color: #ecf5fa;
    border-radius: 0.04rem 0.04rem 0rem 0rem;
    border: solid 0.01rem #dfeef6;
    .td{
      &:first-child{
        padding-left: .12rem;
      }
    }
  }
  .td{
    width: 2rem;
    text-align: center;
    &:first-child{
      flex: 1;
      text-align: left;
    }
  }
  .tr{
    border-bottom: solid 0.01rem #e6f2f8;
    border-left: solid 0.01rem #e6f2f8;
    &:hover{
      box-shadow: 0 0 .84rem rgba($color: #2060ac, $alpha: .15);
    }
    .td{
      border-right: solid 0.01rem #e6f2f8;
      padding: .2rem;
      display: flex; align-items: center; justify-content: center;
      &:first-child{
        justify-content: flex-start;
      }
    }
  }
  .status{
    img{
      width: .14rem; margin-right: .05rem;
    }
  }
}
.goods{
  width: 100%;
  &-cover{
    width: 1rem; height: 1rem;
    object-fit: cover;
    border-radius: .04rem;
    margin-right: .19rem;
  }
  .text{
    flex: 1;
    &-item{
      line-height: calc(30 / 16 * 1em);
      margin-bottom: .08rem;
    }
  }
  .tags{
    margin-top: .12rem;
    .tag{
      border: 1px solid;
      border-radius: .04rem;
      margin-right: .1rem;
      padding: .01rem .04rem;
    }
  }
}
.md-as-2{
  padding: .27rem .3rem .4rem;
  margin-top: .2rem;
  .form{
    &-item{
      display: flex;
      margin-bottom: .26rem;
      &:last-of-type{
        margin-bottom: 0;
      }
      &_label{
        min-width: 1.38rem;
        padding-right: .3rem;
        position: relative;
        text-align: right;
        &.required {
          .p_label{
            position: relative;
            &:after{
              content: '*';
              color: #f53e39;
              position: absolute; right: -.5em; top: .02rem;
            }
          }
          .p_tips{
            text-align: center;
          }
        }
        &.h50{
          line-height: .5rem;
        }
      }
      &_content{
        flex: 1;
      }
    }
    ::v-deep .ipt{
      width: 100%; height: .5rem;
      border-radius: .04rem;
      border: solid 1px #cae1ec;
      padding: 0 .18rem;
      transition: .3s;
      &::placeholder{
        color: #999;
      }
      &:focus{
        border-color: $blue;
      }
    }
    textarea.ipt{
      display: block;
      height: 1.5rem;
      padding: .14rem .14rem;
    }
    .el-textarea{
      font-size: inherit; color: inherit;
      ::v-deep{
        .el-textarea__inner{
          padding: .14rem .14rem;
          height: 1.5rem;
          border: solid 1px #cae1ec;
          resize: none;
          &::placeholder{
            color: #999;
          }
        }
        .el-input__count{
          font-size: .16rem;
          right: .2rem; bottom: .15rem;
        }
      }
    }
    .btn{
      width: 1.5rem;
    }
    ::v-deep{
      .c-radio{
        margin-top: 0; margin-bottom: 0;
      }
    }
  }
  .sub-form{
    &-item{
      margin-bottom: .28rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    .f1{
      width: 1.27rem;
    }
    .f2{
      width: 4.4rem;
    }
  }
}
</style>